import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link} from 'react-router-dom';
import {Home} from './components/Home';
import {Graph} from './components/Graph';
import './App.css';

function App() {
  return (
    <Router>
      <div class="component">
        {/*<nav>
          <Link to="/" className="nav-link">Home</Link>
          {' | '}
          <Link to="/graph" className="nav-link">Graph</Link>
        </nav>*/}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/graph" element={<Graph />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;


