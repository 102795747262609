import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import supabase from './Supabase';

export const Home = () => {
  const [symbols, setSymbols] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSymbols = async () => {
      try {
        let { data, error } = await supabase
          .from('Symbols')
          .select('*')
          .gte('Date', '2021-03-24T00:00:00+00'); // Filtra datos desde 2021-03-24
        
        if (error) throw error;
        
        setSymbols(data);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchSymbols();
  }, []); // Dependencias vacías para ejecutar solo al montar el componente

  const headers = symbols.length > 0 ? Object.keys(symbols[0]) : [];

  let xValues = [];
  let yValues = [];

  if (symbols.length > 0) {
    // La primera columna será el eje Y
    const yLabel = headers[0];
    yValues = symbols.map(symbol => symbol[yLabel]);

    // El resto de las columnas serán los ejes X
    const xLabels = headers.slice(1);
    xValues = xLabels.map(label => ({
      x: label,
      y: symbols.map(symbol => symbol[label]),
    }));
  }

  // Define un array de colores para cada serie de datos
  const colors = ['blue', 'red', 'green', 'orange', 'purple', 'black', 'yellow'];

  return (
    <div>
      <h1>Tickers Graphs Display</h1>
      
      {error && <p>Error: {error}</p>}
      
      {/* Mostrar la primera gráfica */}
      {symbols.length > 0 && (
        <div className="graph-container">
          <Plot
            data={xValues.map((xValue, index) => ({
              x: yValues,
              y: xValue.y,
              type: 'scatter',
              mode: 'lines+markers',
              name: xValue.x,
              line: {
                color: colors[index % colors.length], // Color único para cada línea
                width: 1, // Grosor de la línea (delgada)
                dash: 'solid', // Estilo de la línea (punteada)
              },
              marker: {
                color: 'darkblue', // Color de los puntos
                size: 1, // Tamaño de los puntos
                opacity: 0.8, // Opacidad de los puntos
              },
            }))}
            layout={{ width: 1320, height: 440, title: 'Analytics Viewer' }}
          />
        </div>
      )}

      {/* Mostrar la gráfica 3D */}
      {symbols.length > 0 && (
        <div className="graph-container">
          <Plot
            data={[
              {
                x: symbols.map(symbol => symbol[headers[1]]), // Usa la segunda columna como eje X
                y: symbols.map(symbol => symbol[headers[2]]), // Usa la tercera columna como eje Y
                z: symbols.map(symbol => symbol[headers[3]]), // Usa la cuarta columna como eje Z
                type: 'scatter3d',
                mode: 'markers',
                marker: {
                  size: 5,
                  color: 'rgb(23, 190, 207)', // Color de los puntos
                },
              },
            ]}
            layout={{ width: 1320, height: 440, title: '3D Analytics Viewer' }}
          />
        </div>
      )}

      
    </div>
  );
};

export default Home;
